
import Vue from "vue";
import { agencyMapActions, agencyMapMutations } from "@/store/modules/agency";
import { companiesMapActions } from "@/store/modules/companies";
import { userMapActions } from "@/store/modules/user";
import { cloneDeep, get, omit, set } from "lodash";
import { IUserModel } from "@/types";
import { authMapActions } from "@/store/modules/auth";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import AgenciesMixin, { IAgencyData } from "./AgenciesMixin.vue";
import StatusTag from "@/components/User/StatusTag.vue";

export default Vue.extend({
  name: "editAgency",
  mixins: [ActivitiesMixin, AgenciesMixin],
  components: {
    UsersListView: () =>
      import("@/components/User/ListUsers/ListUsersTable.vue"),
    UserListPickerModal: () =>
      import("@/components/User/ListUsers/UserListPickerModal.vue"),
    SaveChanges: () => import("@/components/Dialog/saveChanges.vue"),
    ListAgenciesTable: () =>
      import("@/components/Agency/ListAgency/ListAgenciesTable.vue"),
    AgencyCodePrefixPickerModal: () =>
      import("@/components/Agency/ListAgency/AgencyCodePrefixPickerModal.vue"),
    StatusTag
  },
  data(): Partial<IAgencyData> {
    return {};
  },
  async beforeRouteLeave(to, from, next): Promise<void> {
    const hasAgencyUpdates = Object.keys(this.updatedFields).length > 0;
    if (hasAgencyUpdates && !this.hasUpdatedAgencyData) {
      this.nextRoute = next;
      this.$modal.show("discard-changes-modal");
    } else {
      return next();
    }
  },

  created(): void {
    this.$router.replace({ query: undefined });
    Promise.allSettled([
      this.getCompanies({ query: {} }),
      this.getAgency(this.agencyId)
    ]).then((values: any) => {
      if (values && values[0] && values[0].status == "rejected") {
        this.errorMessage = "Error retrieving company data";
        this.agencyNOtFound = true;
      } else if (values && values[1] && values[1].status == "rejected") {
        this.errorMessage = "Error retrieving agency data";
        this.agencyNOtFound = true;
      }
      const newValue = cloneDeep(values[1].value);
      if (!get(newValue, "phone.primary")) {
        set(newValue, "phone.primary", "");
      }
      if (!get(newValue, "phone.secondary")) {
        set(newValue, "phone.secondary", "");
      }
      this.SET_EDIT(newValue);
      this.initialized = true;
      this.agencyNOtFound = false;
    });
  },
  destroyed(): void {
    this.SET_EDIT(" " as any);
  },
  methods: {
    ...authMapActions(["init"]),
    ...userMapActions(["getUsers"]),
    ...agencyMapActions([
      "createAgency",
      "deleteAgency",
      "getAgency",
      "updateAgency",
      "assignAgentsToAgencies",
      "getAgencies"
    ]),
    ...agencyMapMutations(["SET_EDIT"]),
    ...companiesMapActions(["getCompanies"]),
    async promptUpdateConfirmation(): Promise<void> {
      try {
        this.$modal.show("agencySingleEditModal");
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    },
    async showSelectModal(): Promise<void> {
      this.$modal.show("selectUserModal");
      this.currentTableShowing = "users-select";
    },
    async hideSelectModal() {
      this.$modal.hide("selectUserModal");
      this.currentTableShowing = "users";
    },
    async tableAction(action: string): Promise<void> {
      await this.$router.replace({ query: undefined });
      this.changingTables = true;
      switch (action) {
        case "Show Sub Agency List":
          this.currentTableShowing = "agency";
          break;
        case "Show Agency Members List":
          this.currentTableShowing = "users";
          break;
      }
      this.changingTables = false;
    },
    goToUserProfile(dataReceived: any) {
      const { _id } = dataReceived;
      if (
        this.$getCurrentUser &&
        (this.$getCurrentUser.role == "agencyAdmin" ||
          this.$getCurrentUser.role == "subAdmin")
      ) {
        return this.$router.push(`/agencyAdmin/users/${_id}`).catch(() => {});
      }
      this.$router.push(`/admin/users/${_id}`).catch(() => {});
    },
    processBulkAction(data: any): void {
      switch (data.action) {
        case get(this.bulkAction, "0.title"):
          this.promptAddUserToAgency(data.selected);
          break;
      }
    },
    async promptAddUserToAgency(data: any[]): Promise<void> {
      let confirmInfo = "Are you sure you want to add: </br></br>";
      let agentIds: any[] = [];
      const getAgencyById: Function = this.getAgencyById;
      const editing: any = this.editing;
      const selected: any[] = data;
      agentIds = selected.map((d: IUserModel) => {
        return d._id;
      });
      const agencyIds = [
        ...new Set(
          selected
            .map((d: IUserModel) => {
              return d.agencyId;
            })
            .filter((id: string) => id)
        )
      ];
      try {
        if (agencyIds.length) {
          await this.getAgencies({ query: { _id__in: agencyIds } });
        }
      } finally {
        selected.slice(0, 10).map((d: IUserModel) => {
          const agency = getAgencyById(d.agencyId);
          confirmInfo += `${d.firstName} ${d.lastName}`;
          if (agency && agency.agencyCode) {
            confirmInfo += `${
              d.agencyId && agency
                ? ` from <b> ${agency.agencyName} (${agency.agencyCode}) </b>`
                : ""
            } </br>`;
          } else {
            confirmInfo += "</br>";
          }
        });
        if (selected.length > 10) {
          confirmInfo += `...and ${selected.length - 10} more`;
        }
      }

      confirmInfo += `<br> to <b>${editing.agencyName} (${editing.agencyCode})</b>?`;

      confirmInfo += `
      </br>
      </br>
      Note
      </br>
      <b>
        * Agent codes of all selected agents will be set to the agency code of this new agency
      </b>
      </br>
      <b> * Companies not assigned to the current Agency will be removed from agent's companies list.
      </b>
      `;
      await this.$confirm(confirmInfo, "Info", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        dangerouslyUseHTMLString: true,
        type: "warning",
        closeOnClickModal: false,
        showClose: false,
        cancelButtonClass:
          "el-button rounded-none ml-0 mr-0 el-button--primary el-button--medium is-plain"
      });
      await this.addUserToAgency(agentIds);
    },
    async addUserToAgency(agentIds: any[]): Promise<void> {
      try {
        const agentsSize = agentIds.length;
        const getEditingAgency: any = this.getEditingAgency;
        if (agentsSize && this.getEditingAgency) {
          await this.assignAgentsToAgencies({
            id: getEditingAgency._id,
            agentIds
          });
          const agentString = agentsSize > 1 ? "Agents" : "Agent";
          this.$appNotifySuccess(`${agentString} Added Successfully`);
          this.hideSelectModal();
        }
      } catch (error) {
        this.$notify.error(error.message);
        this.$bugSnagClient.notify(error);
      }
    },
    userListToButtonClicked(topButton: string): void {
      const topButtonForUserList: any = this.topButtonForUserList;
      const editing: any = this.editing;
      switch (topButton) {
        case topButtonForUserList[0]:
          this.$router.push({
            path: "/admin/users/new",
            query: { agencyId: editing._id }
          });
          break;
      }
    },
    onToolbarItemClick(event: string): void {
      switch (event) {
        case "update":
          this.promptUpdateConfirmation();
          break;
        case "upload-banned-agents":
          if (this.$refs && this.$refs.usersListView)
            (this.$refs.usersListView as any).uploadBannedOrRestrictedUsers(
              "banned"
            );
          break;
        case "upload-restricted-agents":
          if (this.$refs && this.$refs.usersListView)
            (this.$refs.usersListView as any).uploadBannedOrRestrictedUsers(
              "restricted"
            );
          break;
        case "cancelEditingAgency":
          this.createCallback();
          break;
        case "addUser":
          this.showSelectModal();

          break;
      }
    },
    async deleteCallback(): Promise<void> {
      try {
        this.$modal.show("agencyDeleteModal");
      } catch (error) {
        console.error(error);
      }
    },
    async deleteOneAgency(): Promise<void> {
      try {
        await this.deleteAgency(this.agencyId);
        this.$modal.hide("agencyDeleteModal");
        this.$appNotifySuccess("Agency Successfully Deleted");
        this.$router.replace("/admin/agencies").catch(() => {});
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      }
    },

    async submit(beforeRouteLeave = false): Promise<void> {
      try {
        if (this.formIsValid || beforeRouteLeave) {
          let fieldsUpdated: any = cloneDeep(this.updatedFields);
          fieldsUpdated.isOnboarding = this.isOnboarding;
          if (!this.isAdmin) {
            fieldsUpdated = omit(fieldsUpdated, [
              "allowInsurorsSeacoast",
              "allowInsurorsSelectSeacoast"
            ]);
          }
          await this.updateAgency({
            id: this.agencyId,
            update: fieldsUpdated
          });
          this.hasUpdatedAgencyData = true;
          this.$appNotifySuccess("Successfully updated agency");
          if (this.isAdmin) {
            this.$router.replace("/admin/agencies").catch(() => {});
          } else {
            this.$router.replace("/").catch(() => {});
          }
        } else {
          this.errorMessage = "Please Fill All Required Fields.";
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      }
    },
    async createCallback(): Promise<any> {
      const { role } = this.$getCurrentUser;
      if (!this.isOnboarding && ["subAdmin", "agencyAdmin"].includes(role)) {
        this.$router.push({
          path: "/dashboard"
        });
        return;
      }
      this.$router.push("/admin/agencies").catch(() => {});
    },

    async beforeRouteLeaveSave() {
      try {
        this.$modal.hide("discard-changes-modal");
        await this.submit(true);
        const nextRoute = this.nextRoute as Function;
        nextRoute();
      } catch (error) {
        this.$bugSnagClient.notify(error);
        const nextRoute = this.nextRoute as Function;
        nextRoute(false);
      }
    },
    async beforeRouteLeaveDiscardChanges() {
      try {
        this.$modal.hide("discard-changes-modal");
        const nextRoute = this.nextRoute as Function;
        nextRoute();
      } catch (error) {
        this.$bugSnagClient.notify(error);
        const nextRoute = this.nextRoute as Function;
        nextRoute(false);
      }
    }
  },
  computed: {
    subOptions(): { title: string; command: string }[] {
      const option = [
        {
          title: "Cancel",
          command: "cancelEditingAgency"
        }
      ];
      if (this.$getCurrentUser?.role == "admin") {
        option.push(
          { title: "Upload Banned Agents", command: "upload-banned-agents" },
          {
            title: "Upload Restricted Agents",
            command: "upload-restricted-agents"
          }
        );
      }
      return option;
    },
    agencyCode(): string {
      return get(this.editing, "agencyCode", "");
    },
    queryOverride() {
      return {
        agencyId: this.agencyId,
        allAgencyCodes__in: this.agencyCode
      };
    },
    topPrimaryButton() {
      if (get(this.editing, "deleted", false)) {
        return null;
      }
      return {
        text: "Update",
        key: "update",
        disabled: this.disablePrimaryToolbar,
        loading: this.makingApiRequest
      };
    }
  }
});
